import { GetSettingsResponse } from '../../models/settings';

export interface HeaderSettingsShape {
  supplierName: string;
  iconURL: string;
  logoURL: string;
  headerDescription: string;
}

export const toHeaderSettingsShape = (
  settingsResponse: GetSettingsResponse
): HeaderSettingsShape => ({
  supplierName: settingsResponse.supplier_name || '',
  headerDescription: settingsResponse.booking_widget_design_params?.header_description_text || '',
  iconURL: settingsResponse.icon_url || '',
  logoURL: settingsResponse.logo_url || '',
});

import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';

import { getConfigQueryString } from 'lib/util/getConfigQueryString';

export const useQueryString = (): string => {
  const { i18n } = useTranslation();

  const router = useRouter();

  return getConfigQueryString(router?.query, i18n.language);
};

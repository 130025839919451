import { useQueryString } from 'hooks/useQueryString';
import { useRelativeUrl } from 'hooks/useRelativeUrl';
import { appendQueryString } from 'lib/util/appendQueryString';

export const useUrl = (path: string) => {
  const queryString = useQueryString();

  const relativeUrl = useRelativeUrl(path);

  return appendQueryString(relativeUrl, queryString);
};

import Skeleton from '@mui/material/Skeleton';
import * as React from 'react';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';

import { useUrl } from 'hooks/useUrl';
import { useEssentialPageLinks } from 'hooks/useEssentialPageLinks';
import { toHeaderSettingsShape } from 'components/Header/util';
import { ApiKeyContext } from 'contexts/ApiKeyContext';
import { BookingWidgetThemeContext } from 'contexts/BookingWidgetThemeContext';
import { ReduxState } from 'ducks';
import { selectLanguageOptions } from 'ducks/server/settings';
import { useCustomerLedgerEnabled } from 'hooks/useCustomerLedgerEnabled';

import { LoginItem } from './LoginItem';
import styles from './BookingWidgetHeader.module.css';
import { useQueryString } from 'hooks/useQueryString';
import { useHeaderDescriptionTagType } from 'hooks/useHeaderDescriptionTagType';
import { WebPushNotificationEnableIcon } from 'components/WebPushNotificationEnableIcon/WebPushNotificationEnableIcon';

const BookingWidgetHeader = () => {
  const { apiKey, useApiKeyInPaths } = React.useContext(ApiKeyContext);
  const { t } = useTranslation();
  const router = useRouter();

  const [mobileMenuIsOpen, setMobileMenuIsOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!mobileMenuIsOpen) {
      document.body.classList.remove(styles['c-header__fixed']);
    } else {
      document.body.classList.add(styles['c-header__fixed']);
    }

    return () => document.body.classList.remove(styles['c-header__fixed']);
  }, [mobileMenuIsOpen]);

  const apiSettings = useSelector((state: ReduxState) => state.server.settings.all);
  const languageOptions = useSelector(selectLanguageOptions);

  const myPageFeatureEnabled = useSelector(
    (state: ReduxState) =>
      state.server.settings.all?.subscriptions?.some(
        (subscription) =>
          subscription.key === 'feature-guest-my-page' && subscription.status === 'ACTIVE'
      ) ?? false
  );
  const customerLedgerEnabled = useCustomerLedgerEnabled();

  const showReservationsPage =
    myPageFeatureEnabled &&
    !!apiSettings?.guest_my_page_settings?.allow_viewing_reservation_details &&
    !customerLedgerEnabled;

  const bookingWidgetTheme = React.useContext(BookingWidgetThemeContext);
  const [isScrolled, setIsScrolled] = React.useState<boolean>(false);

  React.useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    // Attach scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup: detach event listener when the component is unmounted
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [useLargerMaxHeightWhenScrolled, setUseLargerMaxHeightWhenScrolled] = React.useState<
    boolean
  >(true);

  React.useEffect(() => {
    if (mobileMenuIsOpen) {
      if (isScrolled) {
        setUseLargerMaxHeightWhenScrolled(true);
      }
    } else {
      setUseLargerMaxHeightWhenScrolled(false);
    }
  }, [isScrolled, mobileMenuIsOpen]);

  const handleLanguageChange = (lng: string) => {
    const urlParts = window.location.href.split('?');
    const searchParams = new URLSearchParams(urlParts.length > 1 ? urlParts[1] : '');
    searchParams.set('lng', lng);

    window.location.href = `${urlParts[0]}?${searchParams.toString()}`;
  };
  const settings = toHeaderSettingsShape(apiSettings);

  const productListEnabled =
    Boolean(apiSettings.enable_product_list_page) && !router.asPath?.includes('qrreader');

  const showMenus = !router.asPath?.includes('qrreader');

  const configQueryString = useQueryString();

  const reservationsUrl = useUrl('/reservations/verify');

  const pageDescriptionTag = useHeaderDescriptionTagType();

  let productListPath = useApiKeyInPaths ? `/${apiKey}` : '/';
  if (configQueryString) {
    productListPath = `${productListPath}?${configQueryString}`;
  }
  const essentialPageLinks = useEssentialPageLinks();

  const showSupplierName = bookingWidgetTheme !== 'VIBRANT';

  return (
    <>
      {pageDescriptionTag === 'h1' ? (
        <h1 className="base-h1">{settings.headerDescription}</h1>
      ) : (
        <div className="base-h1">{settings.headerDescription}</div>
      )}
      <header className={styles['c-header']}>
        <div className={styles['c-header__inner']}>
          {productListEnabled ? (
            <Link href={productListPath} className={styles['c-header__ttl']}>
              <div
                className={styles['c-header__ttl__logo']}
                style={
                  showSupplierName
                    ? undefined
                    : {
                        border: 'none',
                      }
                }
              >
                {settings.logoURL ? (
                  <picture>
                    <img
                      src={settings.logoURL}
                      alt="Logo"
                      style={
                        showSupplierName
                          ? undefined
                          : {
                              maxWidth: 'none',
                            }
                      }
                    />
                  </picture>
                ) : (
                  <Skeleton variant="rectangular" width={32} height={32} />
                )}
              </div>
              {showSupplierName && (
                <div className={styles['c-header__ttl__name']}>{settings.supplierName}</div>
              )}
            </Link>
          ) : (
            <div className={styles['c-header__ttl']}>
              <div className={styles['c-header__ttl__logo']}>
                {settings.logoURL ? (
                  <picture>
                    <img src={settings.logoURL} alt="Logo" />
                  </picture>
                ) : (
                  <Skeleton variant="rectangular" width={32} height={32} />
                )}
              </div>
              <div className={styles['c-header__ttl__name']}>{settings.supplierName}</div>
            </div>
          )}
          <WebPushNotificationEnableIcon />

          {showMenus && (
            <>
              <input
                className={styles['c-header__flg']}
                type="checkbox"
                id="menu"
                checked={mobileMenuIsOpen}
                onChange={() => {
                  setMobileMenuIsOpen(!mobileMenuIsOpen);
                }}
              />
              <label className={styles['c-header__menu']} htmlFor="menu">
                <span />
              </label>

              <div
                className={clsx(
                  useLargerMaxHeightWhenScrolled ? styles['c-header__nav-scrolled'] : null,
                  styles['c-header__nav']
                )}
              >
                <ul className={clsx(styles['c-header__nav__list'], 'maincolor-txt')}>
                  {showReservationsPage && (
                    <li className={styles['c-header__nav__list__item']}>
                      <Link href={reservationsUrl}>{t('Reservations')}</Link>
                    </li>
                  )}
                  <li className={styles['c-header__nav__list__item']}>
                    <div className={styles['c-header__nav__list__item__childrenMenu']}>
                      {languageOptions.length > 1 && (
                        <>
                          <p>{t('Language')}</p>
                          <ul className="maincolor-border" style={{ left: 'calc(-220px)' }}>
                            {languageOptions.map((languageOption) => (
                              <li key={languageOption.iso}>
                                <a
                                  onClick={() => {
                                    handleLanguageChange(languageOption.iso);
                                  }}
                                >
                                  {languageOption.name}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </>
                      )}
                    </div>
                  </li>
                  {(essentialPageLinks || []).length > 0 && (
                    <li className={styles['c-header__nav__list__item']}>
                      <div className={styles['c-header__nav__list__item__childrenMenu']}>
                        <p>{t('Info')}</p>
                        <ul className="maincolor-border" style={{ left: 'calc(-220px)' }}>
                          {essentialPageLinks.map((link, idx) => (
                            <li key={idx}>
                              <Link href={link.url}>{link.text}</Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </li>
                  )}
                  {customerLedgerEnabled && <LoginItem />}
                </ul>
              </div>
            </>
          )}
        </div>
      </header>
    </>
  );
};

export default BookingWidgetHeader;

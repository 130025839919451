import { useRouter } from 'next/router';

export const useHeaderDescriptionTagType = () => {
  const router = useRouter();

  // If this is a top page, the tag type should be 'h1'.
  const isTopPage = router.pathname === '/top' || router.pathname === '/[apiKey]';

  // Otherwise, the tag type should be 'div'.
  return isTopPage ? 'h1' : 'div';
};

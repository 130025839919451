import { useTranslation } from 'react-i18next';
import { useSubscriptionEnabled } from './useSubscriptionEnabled';

export const useLoginText = () => {
  const { t } = useTranslation();

  const guestMyPageEnabled = useSubscriptionEnabled('feature-guest-my-page');

  let text = t('Login');

  if (guestMyPageEnabled) {
    text = text + '/' + t('Reservations');
  }

  return text;
};

// appendQueryString appends a query string to a URL.
export const appendQueryString = (url: string, query: string): string => {
  if (!query) {
    return url;
  }
  if (url.includes('?')) {
    return `${url}&${query}`;
  }

  return `${url}?${query}`;
};

import * as React from 'react';
import ReactDOM from 'react-dom';
import clsx from 'clsx';

import styles from './Modal.module.css';
import { Wrapper } from 'components/Wrapper/Wrapper';

type Props = {
  children: React.ReactNode;
  open: boolean;
  onClose?: () => void;
  maxWidth?: string;
  insertRoot?: boolean;
};

export const Modal = ({
  children,
  open,
  onClose,
  maxWidth,
  insertRoot,
}: Props): React.ReactElement => {
  const rootEl = typeof window !== 'undefined' ? document?.getElementById('__next') : undefined;

  return (
    <Wrapper
      wrapper={(children) =>
        insertRoot && rootEl ? ReactDOM.createPortal(children, rootEl as Element) : children
      }
    >
      <div className={clsx(styles['c-modal-calendar'], open && styles['open'])}>
        <div className={styles['c-modal-calendar__overlay']}></div>
        <div className={styles['c-modal-calendar__frame']} style={{ maxWidth }}>
          {onClose && (
            <div
              className={styles['c-modal-calendar__frame__close']}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onClose?.();
              }}
            />
          )}
          <div className={styles['c-modal-calendar__frame__body']}>{children}</div>
        </div>
      </div>
    </Wrapper>
  );
};

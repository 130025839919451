import * as React from 'react';
import { Box, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import config from 'config';
import { Modal } from 'components/Modal/Modal';
import { ReduxState } from 'ducks';
import { enableWebPushNotification } from 'ducks/client/webPushNotification';

import styles from './WebPushNotificationEnableIcon.module.css';

export const WebPushNotificationEnableIcon = () => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = React.useState(false);
  const { t } = useTranslation();

  const webPushNotificationEnabled = useSelector(
    (state: ReduxState) => state.webPushNotification.webPushNotificationEnabled
  );

  if (!config.enableMarketingAutomationWebPush) {
    return null;
  }

  return (
    <>
      <div
        className={styles['c-header__notification_icon']}
        onClick={() => {
          setOpenModal(true);
        }}
      >
        <img src="/static/images/ic_notification.svg" alt="" />
      </div>

      {openModal && (
        <Modal
          open
          onClose={() => {
            setOpenModal(false);
          }}
        >
          <Box className={styles['title']} display="flex" justifyContent="center">
            <h1>{t('Enable Web Push Notification')}</h1>
          </Box>

          <Box ml={1} mt={2}>
            {webPushNotificationEnabled ? (
              <span
                style={{
                  fontSize: '14px',
                  fontFamily:
                    "Helvetica, Arial, Verdana, Roboto, 'ヒラギノ角ゴ Pro', 'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo', sans-serif",
                }}
              >
                {t('Notifications Enabled')}
              </span>
            ) : (
              <Button
                onClick={() => {
                  try {
                    Notification?.requestPermission().then((permission) => {
                      if (permission === 'granted') {
                        dispatch(enableWebPushNotification(true));
                      }
                    });
                  } catch (err) {
                    console.log('err', err);
                  }
                }}
              >
                {t('Enable Notifications')}
              </Button>
            )}
          </Box>
        </Modal>
      )}
    </>
  );
};

import * as React from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import config from 'config';
import {
  CustomerTokenType,
  CustomerTokenContextType,
  CustomerTokenContext,
} from 'contexts/CustomerTokenContext';
import { ReduxState } from 'ducks';
import { ApiKeyContext } from 'contexts/ApiKeyContext';

export const useCustomerToken = (): CustomerTokenContextType => {
  const context = React.useContext(CustomerTokenContext);

  const { i18n } = useTranslation();
  const apiSettings = useSelector((state: ReduxState) => state.server.settings.all);
  const { apiKey } = React.useContext(ApiKeyContext);

  const now = new Date().getTime();

  const buffer = 10 * 60 * 1000; // 10 minute

  if (context?.expiresAt !== 0 && (context?.expiresAt ?? 0) < now + buffer) {
    if (context?.idProvider === 'COGNITO' && context?.refreshToken) {
      const tokenUrl = `https://${
        apiSettings?.customer_ledger_settings?.cognito?.domain ?? ''
      }/oauth2/token`;
      const clientId = apiSettings.customer_ledger_settings?.cognito?.client_id ?? '';
      fetch(tokenUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `grant_type=refresh_token&client_id=${clientId}&refresh_token=${context.refreshToken}`,
      })
        .then((response) => response.json())
        .then((data) => {
          const newContext: CustomerTokenType = {
            accessToken: data.access_token,
            idToken: data.id_token,
            refreshToken: data.refreshToken,
            expiresAt: now + data.expires_in * 1000,
            idProvider: context.idProvider,
            apiKey: context.apiKey,
          };
          context.setToken(newContext);
        })
        .catch((error) => {
          console.error(error);
          context.logout();
        });
    } else if (context?.idProvider === 'GOOGLE' && context?.refreshToken) {
      axios
        .get(
          `${config.apiUrl}/customers/-/token?id_provider=${context?.idProvider}&refresh_token=${context.refreshToken}&grant_type=refresh_token`,
          {
            headers: { 'x-api-key': apiKey, 'accept-language': i18n.language },
          }
        )
        .then((response) => {
          const customerToken = response.data;
          let expiresAt = 0;
          if (customerToken.expiry_date_time_utc) {
            expiresAt = new Date(customerToken.expiry_date_time_utc).getTime();
          }
          const newToken = {
            idToken: customerToken.id_token ?? '',
            accessToken: customerToken.access_token ?? '',
            refreshToken: customerToken.refresh_token ?? '',
            expiresAt: expiresAt,
            idProvider: context.idProvider,
            apiKey: context.apiKey,
          };
          context.setToken(newToken);
        });
    }
  }

  return context;
};
